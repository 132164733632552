<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_provider"
              :items="productores"
              item-text="name"
              item-value="id"
              label="Escoge un Cliente"
              @change="getByCustomer"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
    </v-container>

      <!-- :items="currentItems" -->
    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
      
    >
      <template v-slot:[`item.oro`]="{ item }">
        {{ numberWithCommas((parseFloat(item.oro)).toFixed(2)) }}
      </template>
      <template v-slot:[`item.uva`]="{ item }">
          <div v-if="item.uva_>0">
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            {{ item.uva_ }} 
              <v-icon small> fas fa-arrow-circle-right </v-icon> 
            {{ item.uva }}
          </div>
      </template>
      <template v-slot:[`item.ps`]="{ item }">
          <div v-if="item.ps_>0">
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            {{ item.ps_ }} 
              <v-icon small> fas fa-arrow-circle-right </v-icon> 
            {{ item.ps }}
          </div>
      </template>
      <template v-slot:[`item.pm`]="{ item }">
          <div v-if="item.pm_>0">
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            {{ item.pm_ }} 
              <v-icon small> fas fa-arrow-circle-right </v-icon> 
            {{ item.pm }}
          </div>
      </template>
      <template v-slot:[`item.ph`]="{ item }">
          <div v-if="item.ph_>0">
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            {{ item.ph_ }} 
              <v-icon small> fas fa-arrow-circle-right </v-icon> 
            {{ item.ph }}
          </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                class="mr-2" 
                v-bind="attrs"
                v-on="on"
                color="primary"
                @click="addList(item)">fas fa-list-alt</v-icon>
              </template>
              <span>Agregar Lista de Productores</span>
            </v-tooltip>
            
      </template>
      <template v-slot:top>
          <v-dialog 
          v-model="dialogList" 
          max-width="900px"
          >
            <!-- <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template> -->
            <v-card>
              
              <v-card-title>
                <v-col cols="12" sm="12" md="4">
                <span class="headline">Productores</span>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                <span class="title" >Total Oro: {{ numberWithCommas(parseFloat(total_oro).toFixed(2)) }}</span>
                </v-col>
                  
                <!-- <v-spacer></v-spacer> -->

                <v-text-field
                  v-model="search2"
                  append-icon="fas fa-search"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <div>

                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="dialogList = false"
                >
                  <v-icon dark>fa-times</v-icon>
                </v-btn>

                </div>
              </v-card-title>

              <v-card-text>
                <v-container>

                  <v-data-table
                    :headers="headers_customers"
                    :items="customers"
                    :search="search2"
                    sort-by="id"
                    sort-desc
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                  >
                  <template v-slot:[`item.name`]="{ item }">
                      <div v-if="item.name2">
                        {{ item.name2 }} 
                      </div>
                      <div v-else>
                        {{ item.name }} 
                      </div>
                  </template>
                  <!-- BEGIN MODAL CHILD -->
                    <template v-slot:top>
                      <v-toolbar flat color="white">
                        <v-spacer></v-spacer>

                        <v-dialog v-model="dialog" max-width="600px">
                          <template v-slot:activator="{ on }">
                              <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                              <v-spacer></v-spacer>
                              <v-radio-group
                                row
                              >
                                <v-switch
                                    v-model="editedItem.invalid"
                                    color="red"
                                    inset
                                    label="Comprobante no válido"
                                    class="mr-4"
                                  ></v-switch>

                              </v-radio-group>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-form ref="form" v-model="valid">
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-autocomplete
                                        v-model="editedItem.id_productor"
                                        :items="productores"
                                        item-text="name"
                                        item-value="id"
                                        label="Escoge un Cliente"
                                        :rules="editedItem.id_productorRules"
                                        clearable
                                        rounded
                                        solo
                                        return-object
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-text-field
                                          v-model="editedItem.number"
                                          label="#Comprobante de compra"
                                          :rules="editedItem.numberRules"
                                          required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-text-field
                                        v-model="editedItem.oro"
                                        label="Cantidad (ORO)"
                                        :rules="editedItem.oroRules"
                                        type="number"
                                        required
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12">
                                      <v-textarea
                                        solo
                                        auto-grow
                                        outlined
                                        rows="3"
                                        v-model="editedItem.comment"
                                        label="Observación"
                                      ></v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialogDelete" max-width="450">
                        <v-card>
                          <v-card-title>
                            <span>¿Desea eliminar este registro?</span>
                            <v-spacer></v-spacer>
                          </v-card-title>
                          <v-card-text>
                            <div> <b>Productor:</b> {{ editedItem.name }} </div>
                            <div> <b>Cantidad:</b> {{ editedItem.oro }} </div>
                          </v-card-text>
                          <v-card-actions>
                            <!-- <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn color="green darken-1" text @click="eliminar">Aceptar</v-btn> -->

                            <v-row no-gutters style="flex-wrap: nowrap;">
                            <v-btn
                              class="flex-grow-1 flex-shrink-0"
                              large
                              rounded
                              outlined
                              color="warning"
                              @click="close"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              class="flex-grow-1 flex-shrink-0"
                              large
                              rounded
                              color="error"
                              @click="eliminar"
                              >Aceptar</v-btn
                            >
                          </v-row>

                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon small class="mr-2" color="success" @click="editItem(item)">fas fa-edit</v-icon>
                      <v-icon small color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
                    </template>

                    <template slot="body.append">
                      <tr class="gray--text">
                        <!-- <th class="title"></th> -->
                        
                        <th colspan="8" class="title">
                        <v-row class="pt-2">
                          <v-col cols="12" sm="12" md="4">
                            Pendiente:
                            <span class="green--text" v-if="(total_oro - parseFloat(sumField('oro'))) > 0">
                               {{ numberWithCommas((total_oro - parseFloat(sumField('oro'))).toFixed(2)) }}
                            </span>
                            <span class="red--text" v-else>
                               {{ numberWithCommas((total_oro - parseFloat(sumField('oro'))).toFixed(2)) }}
                            </span>
                          </v-col>
                          <v-col offset-md="4" cols="12" sm="12" md="4">
                            Total: {{ numberWithCommas((parseFloat(sumField('oro'))).toFixed(2)) }}
                          </v-col>
                        </v-row>
                        </th>
                        
                      </tr>
                    </template>

                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
      </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import jsPDF from 'jspdf';
import n2wordsES from 'n2words/lib/i18n/ES.mjs'

export default {
  name: "calculatePartner",
  data: () => ({
    dialog: false,
    dialogList: false,
    dialogFactor: false,
    company: {},
    samplings: [],
    currentItems: [],
    items: [],
    providers: [],
    productores: [],
    types: [],
    customers: [],
    damages: [],
    processes: [],
    damages: [],
    process: "",
    type: "",
    total_oro: "",
    total_cash: 0,
    id_provider: null,
    id_partner: null,
    dates: [],
    modal: false,
    loader: null,
    loading3: false,
    dialogDelete: false,
    search: "",
    search2: "",
    loading: false,
    titulo: "Cálculo de Retenciones para Mayoristas",
    headers: [
      // { text: "C", align: "left", value: "codprov" },
      { text: "Nombre", value: "productor" },
      { text: "UVA (ORO)", value: "uva" },
      { text: "P. Seco (ORO)", value: "ps" },
      { text: "P. Mojado (ORO)", value: "pm" },
      { text: "P. Húmedo (ORO)", value: "ph" },
      { text: "TOTAL ORO", value: "oro" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    headers_customers: [
      { text: "Fecha", align: "left", value: "date", width: 105 },
      { text: "Nombre", align: "left", value: "name" },
      { text: "#Comprobante", align: "left", value: "number" },
      { text: "Observación", align: "left", value: "comment" },
      { text: "Total", value: "oro" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    editedIndex: -1,

    valid: true,

    editedItem: {
      id_productor: null,
      oro: "",
      number: "",
      comment: "",
      id_productorRules: [v => !!v || "Escoge el productor"],
      oroRules: [v => !!v || "Escribe la cantidad en oro"],
      numberRules: [
                      v => !!v || "Escribe el número de comprobante",
                      v => (v.length == 7 && v > 0) || 'El número debe tener 7 digitos'
                    ],
    },
    defaultItem: {
      id_productor: null,
      oro: "",
      number: "",
      comment: "",
      id_productorRules: [v => !!v || "Escoge el productor"],
      oroRules: [v => !!v || "Escribe la cantidad en oro"],
      numberRules: [
                      v => !!v || "Escribe el número de comprobante",
                      v => (v.length == 7 && v > 0) || 'El número debe tener 7 digitos'
                    ],
    },
  }),

  computed: {
    
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      let datos;
      await axios.get(this.db + 'retentions_partner',
      {
        headers: this.headers_db
      })
      .then(response => {
          datos = response.data.data;
          this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
      })
      
      let id_productor = null;
      let data = [];

        datos.forEach(item => {
        let element = this.types.filter(doc => {
          return doc.codigo == item.codigo;
        });
        
        let factor  = (element[0].factor / 100);
        if (item.codigo == 2) {
          // console.log(item.cant, element[0].factor, factor);
          item.uva = (parseFloat(item.quantity)).toFixed(2);
          item.uva_ = parseFloat(item.cant);
        }
        else if (item.codigo == 4) {
          item.ps = (parseFloat(item.cant) * parseFloat(factor)).toFixed(2);
          item.ps_ = parseFloat(item.cant);
        }
        else if(item.codigo == 3){
          item.ph = (parseFloat(item.quantity)).toFixed(2);
          item.ph_ = parseFloat(item.cant);
        }
        else if(item.codigo == 7){
          item.pm = (parseFloat(item.cant) * parseFloat(factor)).toFixed(2);
          item.pm_ = parseFloat(item.cant);
        }
        // console.log(id_productor, item.codprov, item.oro);
        if (id_productor == item.codprov || id_productor == null) {
          if (id_productor == null) {
            data.push(item);
          }
        }
        else {
          data.push(item);
        }
        id_productor = item.codprov;
      });

      datos.forEach(element => {
        data.forEach(item => {
          if (element.codprov == item.codprov) {
            console.log(parseFloat(element.uva), "-", parseFloat(element.uva_));
            // console.log(parseFloat(element.quantity));
            
            if (item.oro == undefined) {
              item.oro = 0;
            }
            if (parseFloat(element.uva) > 0) {
              item.uva = element.uva;
              item.uva_ = element.uva_;
              item.oro += parseFloat(element.quantity);
            }
            else if(parseFloat(element.ps) > 0) {
              item.ps = element.ps;
              item.ps_ = element.ps_;
              item.oro += parseFloat(element.quantity);
            }
            else if(parseFloat(element.ph) > 0) {
              item.ph = element.ph;
              item.ph_ = element.ph_;
              item.oro += parseFloat(element.quantity);
            }
            else if(parseFloat(element.pm) > 0) {
              item.pm = element.pm;
              item.pm_ = element.pm_;
              item.oro += parseFloat(element.quantity);
              // item.oro += parseFloat(item.pm);
            }
          }
        });
      });

      this.samplings = this.currentItems = data;
      this.loading = false;
    },

    async getCustomers(){
      await axios.get(this.db + 'retention/providers',
        {
          headers: this.headers_db
        }).then(response => {
          this.productores = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    async getTypes(){
      await axios.get(this.db + 'types',
        {
          headers: this.headers_db
        }).then(response => {
          this.types = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getTypes(); })
        });
    },

    async getDetails(){
      await axios.get(this.db + 'details/'+ this.id_partner,
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.customers.forEach(element => {
            element.invalid = (element.invalid == "1") ? true : false;
            element.name = element.name || element.nombre;
          });
          this.loading = false;
        })
        .catch((error) => {
          this.customers = [];
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getDetails(); })
        });
    },

    addCustomer(payload){
      payload.invalid = this.editedItem.invalid ? 1 : 0,
      console.log(payload);
      axios.post(this.db + 'detail/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        this.generatePdf(this.editedItem);
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getDetails();
        this.close();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.addCustomer(payload); })
      });

    },

    updateCustomer(payload){
      payload.invalid = this.editedItem.invalid ? 1 : 0,
      axios.post(this.db + 'detail/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        this.generatePdf(this.editedItem);
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getDetails();
        this.close();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.updateCustomer(payload); })
      });

    },

    deleteCustomer(id){

      axios.post(this.db + 'detail/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.customers = this.customers.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteCustomer(id); })
      });

    },

    async getCompany(){
      await axios.get(this.db + 'company',
        {
          headers: this.headers_db
        }).then(response => {
          console.log(response.data.data);
          this.company = response.data.data;
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getCompany(); })
        });
    },
    // FIN Metodos base de datos

    // Metodos Generales

    editItem(item) {
      console.log(item);
      this.editedIndex = this.customers.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    sumField(key) {
      return this.customers.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    getByCustomer() {
      console.log(this.id_provider, this.samplings);
      if (this.id_provider) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.codprov == this.id_provider;
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },

    addList(item) {
      console.log(item);
      this.id_partner = item.codprov;
      this.total_oro = item.oro
      this.getDetails();
      this.dialogList = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      console.log(this.editedIndex);
      if (this.$refs.form.validate()) {
        this.editedItem.id_productor2 = this.editedItem.id_productor.id ? this.editedItem.id_productor.id : this.editedItem.id_productor;
        if (this.editedIndex > -1) {
          Object.assign(this.customers[this.editedIndex], this.editedItem);
          this.updateCustomer(this.editedItem);
        } else {
          this.editedItem.id_partner = this.id_partner;
          this.customers.push(this.editedItem);
          this.addCustomer(this.editedItem);
        }
      }
    },

    //Puringla
    async generatePdf(item) {
        console.log('ITEM',item);

        let total_ret = (parseFloat(item.oro)).toFixed(2);
        let price_oro = this.company.price_oro;
        let total_cash = (parseFloat(price_oro) * parseFloat(total_ret)).toFixed(2);

        console.log(total_ret, total_cash);
        const split_ret = total_ret.split(".");
        const qq = n2wordsES(split_ret[0]).toUpperCase();
        const lb = n2wordsES(split_ret[1]).toUpperCase();

        const split_cash = total_cash.split(".");
        const lps = n2wordsES(split_cash[0]).toUpperCase();
        const cent = n2wordsES(split_cash[1]).toUpperCase();

        let ret_text = qq + " QQS. CON " + lb + " LIBRAS ORO";
        let cash_text = lps + " LPS. CON " + cent + " CENTAVOS";

        // console.log(item.id_productor.name ? item.id_productor.name : item.name);
        
        moment.locale('es');
        let place_owner = this.company.dire1;
        let day = moment().format("DD");
        let month = moment().format("MMMM").toUpperCase();
        let year = moment().format("YYYY");
        let owner = this.company.name2;
        let rtn_owner = this.company.rtn;
        let clave_owner = this.company.clave_owner;
        let operation_key = this.company.operation_key;
        
        let provider = item.id_productor.name ? item.id_productor.name : item.name;
        let place_provider = item.id_productor.desbop ? item.id_productor.desbop : item.desbop;
        let rtn = item.id_productor.rtn ? item.id_productor.rtn : item.rtn;
        let clave = item.id_productor.codihc ? item.id_productor.codihc : item.codihc;

        let desmunp = item.id_productor.desmunp ? item.id_productor.desmunp : item.desmunp;
        let desdepp = item.id_productor.desdepp ? item.id_productor.desdepp : item.desdepp;
        let desbof = item.id_productor.desbof ? item.id_productor.desbof : item.desbof;
        let desmunf = item.id_productor.desmunf ? item.id_productor.desmunf : item.desmunf;
        let desdepf = item.id_productor.desdepf ? item.id_productor.desdepf : item.desdepf;
        let telprin = item.id_productor.telprin ? item.id_productor.telprin : item.telprin;

        var doc = new jsPDF();
        // doc.setFont("helvetica", "bold");
        doc.setFontSize(12)
        let beginY = parseInt(this.company.beginY);
        let beginX = parseInt(this.company.beginX);
        // console.log("rewr",beginY);
        doc.text(place_owner, 60, beginY);

        beginY += 9;
        doc.text(day, 85, beginY);
        doc.text(month, 123, beginY, null, null, "center");
        doc.text(year, 169, beginY, null, null, "center");

        beginY += 7;
        doc.text(owner, 85, beginY);

        beginY += 8;
        doc.text(rtn_owner, 35, beginY);
        doc.text(clave_owner, 125, beginY);
        
        beginY += 8;
        doc.text(provider, 95, beginY);
        
        beginY += 8;
        doc.text(rtn, 35, beginY);
        doc.text(clave, 125, beginY);
        
        beginY += 8;
        doc.text(place_provider, 80, beginY);
        
        beginY += 8;
        doc.text(desmunp, 45, beginY);
        doc.text(desdepp, 140, beginY);
        
        beginY += 8;
        doc.text(telprin ? telprin: "", 45, beginY);
        doc.text(desbof, 140, beginY);
        
        beginY += 8;
        doc.text(desmunf, 45, beginY);
        doc.text(desdepf, 140, beginY);
        
        beginY += 18;
        doc.text(split_ret[0] + ".", 19 + beginX, beginY);
        doc.text(split_ret[1], 42 + beginX, beginY);
        doc.text(ret_text, 62, beginY);
        
        beginY += 18;
        doc.text(this.numberWithCommas(split_cash[0]) + ".", 15 + beginX, beginY);
        doc.text(this.numberWithCommas(split_cash[1]), 42 + beginX, beginY);

        // beginY += 19;

        if(cash_text.length > 50){
          let long = cash_text.length;
          let first_line = cash_text.substring(0, 50);
          let last_line = cash_text.substring(50, long);
          doc.text(first_line, 62, beginY - 2);
          doc.text(last_line, 62, beginY + 5);
        }
        else {
          doc.text(cash_text, 62, beginY - 1);
        }
        // doc.text("OCHO MIL CIENTO DIECIOCHO CON CERO CENTAVOS", 60, 158);
        beginY += 17;
        doc.text(operation_key, 25, beginY);

        doc.autoPrint();
        const blob = doc.output("bloburl");
        window.open(blob);
    },

    // async generatePdf(item) {
    //     console.log('ITEM',item);

    //     let price_oro = (parseFloat(this.total_cash) / parseFloat(this.total_oro));
    //     let total_cash = (parseFloat(item.oro) * parseFloat(price_oro)).toFixed(2);
    //     console.log(price_oro, total_cash);
        
    //     let total_ret = (parseFloat(item.oro)).toFixed(2);

    //     const split_ret = total_ret.split(".");
    //     const qq = n2wordsES(split_ret[0]).toUpperCase();
    //     const lb = n2wordsES(split_ret[1]).toUpperCase();

    //     const split_cash = total_cash.split(".");
    //     const lps = n2wordsES(split_cash[0]).toUpperCase();
    //     const cent = n2wordsES(split_cash[1]).toUpperCase();

    //     let ret_text = qq + " QQS. CON " + lb + " LIBRAS ORO";
    //     let cash_text = lps + " LPS. CON " + cent + " CENTAVOS";

    //     // console.log(item.id_productor.name ? item.id_productor.name : item.name);
        
    //     moment.locale('es');
    //     let place_owner = this.company.dire1;
    //     let day = moment().format("DD");
    //     let month = moment().format("MMMM").toUpperCase();
    //     let year = moment().format("YYYY");
    //     let owner = this.company.name2;
    //     let rtn_owner = this.company.rtn;
    //     let clave_owner = this.company.clave_owner;
    //     let operation_key = this.company.operation_key;
        
    //     let provider = item.id_productor.name ? item.id_productor.name : item.name;
    //     let place_provider = item.id_productor.desbop ? item.id_productor.desbop : item.desbop;
    //     let rtn = item.id_productor.rtn ? item.id_productor.rtn : item.rtn;
    //     let clave = item.id_productor.codihc ? item.id_productor.codihc : item.codihc;

    //     let desmunp = item.id_productor.desmunp ? item.id_productor.desmunp : item.desmunp;
    //     let desdepp = item.id_productor.desdepp ? item.id_productor.desdepp : item.desdepp;
    //     let desbof = item.id_productor.desbof ? item.id_productor.desbof : item.desbof;
    //     let desmunf = item.id_productor.desmunf ? item.id_productor.desmunf : item.desmunf;
    //     let desdepf = item.id_productor.desdepf ? item.id_productor.desdepf : item.desdepf;
    //     let telprin = item.id_productor.telprin ? item.id_productor.telprin : item.telprin;

    //     var doc = new jsPDF();
    //     // doc.setFont("helvetica", "bold");
    //     doc.setFontSize(12)
    //     let beginY = parseInt(this.company.beginY);
    //     let beginX = parseInt(this.company.beginX);
    //     // console.log("rewr",beginY);
    //     doc.text(place_owner, 60, beginY);

    //     beginY += 9;
    //     doc.text(day, 85, beginY);
    //     doc.text(month, 123, beginY, null, null, "center");
    //     doc.text(year, 169, beginY, null, null, "center");

    //     beginY += 7;
    //     doc.text(owner, 85, beginY);

    //     beginY += 8;
    //     doc.text(rtn_owner, 35, beginY);
    //     doc.text(clave_owner, 125, beginY);
        
    //     beginY += 8;
    //     doc.text(provider, 95, beginY);
        
    //     beginY += 8;
    //     doc.text(rtn, 35, beginY);
    //     doc.text(clave, 125, beginY);
        
    //     beginY += 8;
    //     doc.text(place_provider, 80, beginY);
        
    //     beginY += 8;
    //     doc.text(desmunp, 45, beginY);
    //     doc.text(desdepp, 140, beginY);
        
    //     beginY += 8;
    //     doc.text(telprin ? telprin: "", 45, beginY);
    //     doc.text(desbof, 140, beginY);
        
    //     beginY += 8;
    //     doc.text(desmunf, 45, beginY);
    //     doc.text(desdepf, 140, beginY);
        
    //     beginY += 18;
    //     doc.text(split_ret[0] + ".", 19 + beginX, beginY);
    //     doc.text(split_ret[1], 42 + beginX, beginY);
    //     doc.text(ret_text, 62, beginY);
        
    //     beginY += 18;
    //     doc.text(this.numberWithCommas(split_cash[0]) + ".", 15 + beginX, beginY);
    //     doc.text(this.numberWithCommas(split_cash[1]), 42 + beginX, beginY);

    //     // beginY += 19;

    //     if(cash_text.length > 50){
    //       let long = cash_text.length;
    //       let first_line = cash_text.substring(0, 50);
    //       let last_line = cash_text.substring(50, long);
    //       doc.text(first_line, 62, beginY - 2);
    //       doc.text(last_line, 62, beginY + 5);
    //     }
    //     else {
    //       doc.text(cash_text, 62, beginY - 1);
    //     }
    //     // doc.text("OCHO MIL CIENTO DIECIOCHO CON CERO CENTAVOS", 60, 158);
    //     beginY += 17;
    //     doc.text(operation_key, 25, beginY);

    //     doc.autoPrint();
    //     const blob = doc.output("bloburl");
    //     window.open(blob);
    // },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async getAll() {
      this.loader = 'loading3'
      this.loading = true;
      await this.getTypes();
      this.getSamplings();
      this.getProviders();
    },
    eliminar() {
      this.deleteCustomer(this.editedItem.id);
      this.close();
    }
  },
  async mounted() {
    this.loading = true;
    await this.getTypes();
    this.getSamplings();
    this.getCustomers();
    this.getCompany();
  },
};
</script>

<style lang="scss">
  .totales {
    font-size: medium !important;
  }
</style>
